<template>
  <div id="Search"></div>
</template>

<script>
export default {
  name: 'Search',
};
</script>

<style lang="scss" scoped></style>
